<template>
    <div>
        <CRow>
            <CCol>
                <CCard accent-color="info">
                    <CCardHeader>
                        <h4>Estado Resultado Comparativo</h4>
                    </CCardHeader>
                    
                    <CCardBody>
                        <CRow>
                            <CCol md="3">
                                <multiselect
                                    class="mb-2"
                                    :custom-label="customLabelEmp"
                                    v-model="empSelected"
                                    selectedLabel="Seleccionado"
                                    deselectLabel="Quitar"
                                    selectLabel="Seleccionar"
                                    placeholder="Seleccionar Empresa" 
                                    label="nombreEmpresa" 
                                    track-by="codEmpresa" 
                                    :options="empresasInfo" 
                                    :multiple="false"
                                    @select="seleccionarEmpresa"></multiselect>
                            </CCol>
                            <CCol md="2">
                                <multiselect class="m-0 d-inline-block mb-2" 
                                    v-model="mesDesdeSelected"
                                    deselect-label="" 
                                    placeholder="Desde" 
                                    select-label=""
                                    selectedLabel=""
                                    label="nombre" 
                                    track-by="valor" 
                                    :options="mesesDesde" 
                                    :searchable="true"
                                    :disabled="empSelected == null"
                                    @select="seleccionarMesDesde"
                                >
                                </multiselect>
                            </CCol>
                            <CCol md="2">
                                <multiselect class="m-0 d-inline-block mb-2" 
                                    v-model="mesHastaSelected"
                                    deselect-label="" 
                                    placeholder="Hasta" 
                                    select-label=""
                                    selectedLabel=""
                                    label="nombre" 
                                    track-by="valor" 
                                    :options="mesesHasta" 
                                    :searchable="true"
                                    :disabled="empSelected == null"
                                    @select="seleccionarMesHasta"
                                >
                                </multiselect>
                            </CCol>
                            <CCol md="1">
                                <multiselect class="m-0 d-inline-block mb-2" 
                                    v-model="anio1Selected"
                                    deselect-label="" 
                                    placeholder="Año 1" 
                                    select-label=""
                                    selectedLabel=""
                                    label="valor" 
                                    track-by="valor" 
                                    :options="aniosDesde" 
                                    :searchable="true"
                                    @select="seleccionarAnioDesde"
                                >
                                </multiselect>
                            </CCol>
                            <CCol md="1">
                                <multiselect class="m-0 d-inline-block mb-2" 
                                    v-model="anio2Selected"
                                    deselect-label="" 
                                    placeholder="Año 2" 
                                    select-label=""
                                    selectedLabel=""
                                    label="valor" 
                                    track-by="valor" 
                                    :options="aniosHasta" 
                                    :searchable="true"
                                    @select="seleccionarAnioHasta"
                                >
                                </multiselect>
                            </CCol>
                            
                            <CCol md="2">
                                <!--<CButton
                                    class="mb-1"
                                    style="width:auto"
                                    variant="ghost"
                                    size="lg"
                                    color="success"
                                    @click="generarPdf"
                                    :disabled="isLoadingPdfEstadoResultados"
                                > 
                                    <CIcon :content="$options.freeSet.cilCloudDownload" /> Descargar pdf
                                </CButton>-->
                                <CRow>
                                    <CCol>
                                        <CButton
                                            v-show="mesDesdeSelected != null && mesHastaSelected != null && anio1Selected != null && anio2Selected != null && empSelected != null"
                                            size="sm"
                                            variant="ghost"
                                            class="btnPrincipalVapp mb-1"
                                            @click="generarPdf"
                                            :disabled="isLoadingPdf">
                                                <CIcon v-if="isLoadingPdf" :content="$options.freeSet.cilCloudDownload" class="imgr" v-c-tooltip.hover="{content: 'Descargar Documento',placement: 'over'}" />
                                                <CIcon v-else :content="$options.freeSet.cilCloudDownload" v-c-tooltip.hover="{content: 'Descargar PDF',placement: 'over'}" />
                                                PDF
                                        </CButton>
                                    </CCol>
                                </CRow>
                                <CRow>
                                    <CCol>
                                        <CButton
                                            v-show="mesDesdeSelected != null && mesHastaSelected != null && anio1Selected != null && anio2Selected != null && empSelected != null"
                                            size="sm"
                                            variant="ghost"
                                            class="btnPrincipalVapp mb-1 ml-1"
                                            @click="generarCsv"
                                            :disabled="isLoadingCsv">
                                                <CIcon v-if="isLoadingCsv" :content="$options.freeSet.cilCloudDownload" class="imgr"  />
                                                <CIcon v-else :content="$options.freeSet.cilCloudDownload" v-c-tooltip.hover="{content: 'Descargar CSV',placement: 'over'}" />
                                                CSV
                                        </CButton>
                                    </CCol>
                                </CRow>
                            </CCol>
                        </CRow>
                        
                        <CRow>
                            <CCol col="12">
                                <a-table
                                    row-key="codigo"
                                    :columns="columnasEstadoResultado" 
                                    :data-source="estadoResultado"
                                    :pagination="{'pageSize': totalPagina}"
                                    :scroll="{ y: 400 }"
                                    :loading="isLoadingEstadoResultados"
                                    class="components-table-demo-nested mt-3" 
                                    size="small">
                                        <span slot="codigo" slot-scope="er">
                                            {{er.codigo}}
                                        </span>
                                        <span slot="descripcion" slot-scope="er">
                                            {{er.descripcion}}
                                        </span>
                                        <span slot="saldoAnio1" slot-scope="er">
                                            {{er.saldoAnio1 | currency}}
                                        </span>
                                        <span slot="saldoAnio2" slot-scope="er">
                                            {{er.saldoAnio2 | currency}}
                                        </span>
                                        <span slot="analisisVert" slot-scope="er">
                                            {{er.analisisVert | currency(configPorcentaje)}}
                                        </span>
                                        <span slot="analisisHor" slot-scope="er">
                                            {{er.analisisHor | currency(configPorcentaje)}}
                                        </span>
                                </a-table>
                            </CCol>
                        </CRow>
                    </CCardBody>
                    <CCardFooter>
                    </CCardFooter>
                </CCard>
            </CCol>
        </CRow>
    </div>
</template>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<script>
    
    import cons from '@/const'
    import axios from "axios"

    import Multiselect from 'vue-multiselect'

    import VueModal from '@kouts/vue-modal';
    import '@kouts/vue-modal/dist/vue-modal.css';
    
    import { freeSet } from '@coreui/icons'

    import moment from 'moment'
    import { format, formatDistance, formatRelative, subDays } from 'date-fns'
    import es from 'date-fns/locale/es'
    window.locale = 'es'

    

    export default {
        name: 'EstadoResultadoComparativo',
        freeSet,
        components: {
            Multiselect,
            'Modal': VueModal
        },
        data () {
            return {
                urlBase: cons.port+"://" + cons.ipServer,

                configPorcentaje: {
                    symbol: '%',
                    thousandsSeparator: '.',
                    fractionCount: 2,
                    fractionSeparator: ',',
                    symbolPosition: 'back',
                    symbolSpacing: false,
                    avoidEmptyDecimals: undefined,
                },
                
                tokenLogin: JSON.parse(this.$cookie.get('userLogginToken')).tokenUsuario,
                tipoUserAdmin: JSON.parse(this.$cookie.get('userLogginToken')).tipoUsuario,
                idCliente: JSON.parse(this.$cookie.get('userLogginToken')).idCliente,
                
                regMail: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,

                mesesDesde: [
                    {"nombre": "Enero", "valor": 1}, 
                    {"nombre": "Febrero", "valor": 2}, 
                    {"nombre": "Marzo", "valor": 3}, 
                    {"nombre": "Abril", "valor": 4}, 
                    {"nombre": "Mayo", "valor": 5}, 
                    {"nombre": "Junio", "valor": 6}, 
                    {"nombre": "Julio", "valor": 7}, 
                    {"nombre": "Agosto", "valor": 8}, 
                    {"nombre": "Septiembre", "valor": 9}, 
                    {"nombre": "Octubre", "valor": 10}, 
                    {"nombre": "Noviembre", "valor": 11}, 
                    {"nombre": "Diciembre", "valor": 12}
                ],
                mesesHasta: [
                    {"nombre": "Enero", "valor": 1, $isDisabled: false}, 
                    {"nombre": "Febrero", "valor": 2, $isDisabled: false}, 
                    {"nombre": "Marzo", "valor": 3, $isDisabled: false}, 
                    {"nombre": "Abril", "valor": 4, $isDisabled: false}, 
                    {"nombre": "Mayo", "valor": 5, $isDisabled: false}, 
                    {"nombre": "Junio", "valor": 6, $isDisabled: false}, 
                    {"nombre": "Julio", "valor": 7, $isDisabled: false}, 
                    {"nombre": "Agosto", "valor": 8, $isDisabled: false}, 
                    {"nombre": "Septiembre", "valor": 9, $isDisabled: false}, 
                    {"nombre": "Octubre", "valor": 10, $isDisabled: false}, 
                    {"nombre": "Noviembre", "valor": 11, $isDisabled: false}, 
                    {"nombre": "Diciembre", "valor": 12, $isDisabled: false}
                ],

                aniosDesde: [
                    {"valor": 2022},
                    {"valor": 2021},
                    {"valor": 2020},
                    {"valor": 2019},
                    {"valor": 2018}
                ],

                aniosHasta: [
                    {"valor": 2022, $isDisabled: false},
                    {"valor": 2021, $isDisabled: false},
                    {"valor": 2020, $isDisabled: false},
                    {"valor": 2019, $isDisabled: false},
                    {"valor": 2018, $isDisabled: false}
                ],
                    

                mesDesdeSelected: null,
                mesHastaSelected: null,
                anio1Selected: null,
                anio2Selected: null,

                empresasInfo: [],

                empSelected: [],
                totalPagina: 20,

                
                isLoadingEmpresasInfo: false,
                isLoadingEstadoResultados: false,
                isLoadingCsv: false,

                columnasEstadoResultado: [
                    { title: 'Código', scopedSlots: { customRender: 'codigo' }, width: '10%', align: 'left', ellipsis: true},
                    { title: 'Descripción', scopedSlots:{ customRender: 'descripcion' }, width: '20%', align: 'right', ellipsis: false},
                    { title: 'Saldo Año 1', scopedSlots: { customRender: 'saldoAnio1' }, width: '15%', align: 'center', ellipsis: true},
                    { title: 'Saldo Año 2', scopedSlots: { customRender: 'saldoAnio2' }, width: '15%', align: 'center', ellipsis: true},
                    { title: 'Análisis Vertical', scopedSlots: { customRender: 'analisisVert' }, width: '15%', align: 'center', ellipsis: true},
                    { title: 'Análisis Horizontal', scopedSlots: { customRender: 'analisisHor' }, width: '15%', align: 'center', ellipsis: true},
                ],
                estadoResultado: [],

            }
        },
        created: function(){},
        beforeMount() {
            let cookieUsername = this.$cookie.get('userLogginToken')
            if(cookieUsername === null || cookieUsername === ""){
                this.$router.push("/pages/login");
            }

        },
        mounted(){
            this.obtenerSoloInfoEmpresas()
        },
        methods: {
            generarPdf: function(){

                this.isLoadingPdf = true

                var url = this.urlBase + "/generar_pdf_estado_resultado";

                axios({
                    method: "POST", 
                    "url": url,
                    "data": {
                        "info": {
                            "idCliente": this.idCliente,
                            "idEmpresa": this.empSelected.idEmpresa,
                            "mesDesde": this.mesDesdeSelected.nombre,
                            "mesHasta": this.mesHastaSelected.nombre,
                            "anio": this.anio1Selected.valor,
                            "comparativo": true,
                            "anio2": this.anio2Selected.valor
                        }
                    },
                    "headers": {
                        'Authorization': `${this.tokenLogin}`
                    }
                }).then(result => {
                    
                    this.isLoadingPdf = false
                    var link = result.data.archivoGenerado.link
                    
                    var fileLink = document.createElement("a");
                    fileLink.href = link;
                    fileLink.setAttribute("download", result.data.archivoGenerado.nombre);
                    document.body.appendChild(fileLink);
                    fileLink.click();

                    this.$notification.success(
                        result.data.mensaje,
                        { timer: 10, position: "bottomRight" }
                    );

                }, error => {
                    this.isLoadingPdf = false
                    this.$notification.error(
                        error.response.data.mensaje,
                        { timer: 10, position: "bottomRight" }
                    );
                });
            },
            generarCsv: function(){
                this.isLoadingCsv = true

                var url = this.urlBase + "/generar_csv_estado_resultado";

                axios({
                    method: "POST", 
                    "url": url,
                    "data": {
                        "info": {
                           "idCliente": this.idCliente,
                            "idEmpresa": this.empSelected.idEmpresa,
                            "mesDesde": this.mesDesdeSelected.nombre,
                            "mesHasta": this.mesHastaSelected.nombre,
                            "anio": this.anio1Selected.valor,
                            "comparativo": true,
                            "anio2": this.anio2Selected.valor
                        }
                    },
                    "headers": {
                        'Authorization': `${this.tokenLogin}`
                    }
                }).then(result => {
                    
                    this.isLoadingCsv = false

                    var link = result.data.archivoGenerado.link
                    
                    var fileLink = document.createElement("a");
                    fileLink.href = link;
                    fileLink.setAttribute("download", result.data.archivoGenerado.nombre);
                    document.body.appendChild(fileLink);
                    fileLink.click();

                    this.$notification.success(
                        result.data.mensaje,
                        { timer: 10, position: "bottomRight" }
                    );

                }, error => {
                    this.isLoadingCsv = false
                    this.$notification.error(
                        error.response.data.mensaje,
                        { timer: 10, position: "bottomRight" }
                    );
                });
            },

            obtenerSoloInfoEmpresas: function(){
                this.isLoadingEmpresasInfo = true
                var url = cons.port+"://" + cons.ipServer + "/get_empresas_info/CONTA";
                
                axios({
                    method: "GET", 
                    "url": url,
                    "headers": {
                        'Authorization': `${this.tokenLogin}`
                    }
                }).then(result => {
                    
                    this.empresasInfo = result.data;

                }, error => {
                    //let mensaje = `Problema al obtener empresas , ${error}`
                    //this.textoSinEmpresas = mensaje
                })
                .finally(() => {
                    this.isLoadingEmpresasInfo = false
                });
            },
            getERComparativo: function(anio2){
                this.isLoadingEstadoResultados = true
                this.anio2Selected = anio2
                this.estadoResultado = []

                var url = this.urlBase + "/get_estado_resultado_empresa";
                
                axios({
                    method: "POST", 
                    "url": url,
                    "data": {
                        "info": {
                            "idCliente": this.idCliente,
                            "idEmpresa": this.empSelected.idEmpresa,
                            "mesDesde": this.mesDesdeSelected.nombre,
                            "mesHasta": this.mesHastaSelected.nombre,
                            "anio": this.anio1Selected.valor,
                            "comparativo": true,
                            "anio2": this.anio2Selected.valor
                        }
                    },
                    "headers": {
                        'Authorization': `${this.tokenLogin}`
                    }
                }).then(result => {
                    
                    this.totalPagina = result.data.length
                    this.estadoResultado = result.data;
                    this.isLoadingEstadoResultados = false

                }, error => {

                    this.isLoadingEstadoResultados = false
                    this.$notification.error(
                        error.response.data.mensaje,
                        { timer: 10, position: "bottomRight" }
                    );
                });

            },
            
            seleccionarEmpresa: function(emp){
                this.empSelected = emp
                if(this.mesDesdeSelected != null && this.mesHastaSelected != null && this.anio1Selected != null && this.anio2Selected != null){
                    this.getERComparativo(this.anio2Selected)
                }

            },
            seleccionarMesDesde: function(mesDesde){
                this.validarMesHasta(mesDesde)
                this.mesDesdeSelected = mesDesde
                if(this.mesHastaSelected != null){
                    if(this.mesHastaSelected.valor < mesDesde.valor){
                        this.mesHastaSelected = null
                    } else{
                        if(this.empSelected != null && this.mesHastaSelected != null && this.anio1Selected != null && this.anio2Selected != null){
                            this.getERComparativo(this.anio2Selected)
                        }
                    }
                }
            },
            seleccionarMesHasta: function(mesHasta){
                this.mesHasta = mesHasta
                if(this.empSelected != null && this.mesDesdeSelected != null && this.anio1Selected != null && this.anio2Selected != null){
                    this.getERComparativo(this.anio2Selected)
                }
            },

            seleccionarAnioDesde: function(anio){
                this.validarAnioHasta(anio)
                this.anio1Selected = anio
                if(this.empSelected != null && this.mesDesdeSelected != null && this.mesHastaSelected != null && this.anio2Selected != null){
                    this.getERComparativo(this.anio2Selected)
                }
            },
            seleccionarAnioHasta: function(anio){
                this.anio2Selected = anio
                if(this.empSelected != null && this.mesDesdeSelected != null && this.mesHastaSelected != null && this.anio1Selected != null){
                    this.getERComparativo(this.anio2Selected)
                }
            },

            validarAnioHasta: function(opcion){
                this.aniosHasta.forEach(anio => {
                    if(anio.valor < opcion.valor){
                        anio.$isDisabled = true
                    } else{
                        anio.$isDisabled = false
                    }
                })
            },

            validarMesHasta: function(opcion){
                this.mesesHasta.forEach(mes =>{
                    if(mes.valor < opcion.valor){
                        mes.$isDisabled = true
                    }
                    else{
                        mes.$isDisabled = false
                    }
                })
            },

            customLabelEmp (empresa) {
                return `(${empresa.codEmpresa}) ${empresa.nombreEmpresa}`;
            },
        }
        
    }
</script>

<style>
    .imgr{
    -webkit-animation: 3s rotate linear infinite;
    animation: 3s rotate  linear infinite;
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    }

</style>